<script setup>
import { Head, Link, useForm } from "@inertiajs/vue3";
import Swal from "@/Components/Swal.vue";
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import ApplicationBrand from "@/Components/ApplicationBrand.vue";
import { trans } from 'laravel-vue-i18n'
import { motion } from 'motion-v'

const form = useForm({
    email: "",
});

const submit = () => {
    form.post(route("newsletter.store"), {
        onSuccess: () => {
            form.reset();
        },
    });
};
</script>

<style></style>

<template>
    <Head :title="trans('Your personalized wall of memories')" />
    <Swal
        :swalErrors="$page?.props?.errors"
        :swalFlash="$page?.props?.flash?.swal"
    />
    <div
        class="landing leading-normal tracking-normal text-indigo-400 bg-cover h-screen bg-[#1f1f1f] bg-[radial-gradient(ellipse_at_left_top,_#2f43c6_0%,_#283480_12%,_#1d224e_100%)]"
    >
        <div class="min-h-screen">
            <!--Nav-->
            <div class="w-full container mx-auto">
                <motion.div
                    :initial="{ opacity: 0, y: -20 }"
                    :animate="{ opacity: 1, y: 0 }"
                    :exit="{ opacity: 0, y: 20 }"
                    :transition="{ duration: 0.3 }"
                    class="w-full flex items-center justify-between p-4"
                >
                    <a
                        class="flex items-center text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
                        href="#"
                    >
                        <ApplicationLogo class="dark block h-9 w-auto mr-4" />
                        <ApplicationBrand class="dark" />
                    </a>

                    <div class="flex w-1/2 justify-end content-center">
                        <nav class="-mx-3 flex flex-1 justify-end">
                            <Link
                                v-if="$page.props.auth.user"
                                :href="route('events.index')"
                                class="rounded-md px-3 py-2 ring-1 ring-transparent transition focus:outline-none focus-visible:ring-[#FF2D20] text-white hover:text-white/80 dark:focus-visible:ring-white"
                            >
                                {{ trans('My events') }}
                            </Link>

                            <template v-else>
                                <Link
                                    :href="route('auth')"
                                    class="rounded-md px-3 py-2 ring-1 ring-transparent transition focus:outline-none focus-visible:ring-[#FF2D20] text-white hover:text-white/80 dark:focus-visible:ring-white"
                                >
                                    {{ trans('My account') }}
                                </Link>
                            </template>
                        </nav>
                    </div>
                </motion.div>
            </div>

            <!--Main-->
            <div
                class="container md:pl-4 mx-auto flex flex-wrap flex-col md:flex-row items-center pt-8"
            >
                <!--Left Col-->
                <motion.div
                    :initial="{ opacity: 0, x: -20 }"
                    :animate="{ opacity: 1, x: 0 }"
                    :exit="{ opacity: 0, x: 20 }"
                    :transition="{ duration: 0.3 }"
                    class="flex flex-col w-full xl:w-2/5 justify-center lg:items-start overflow-y-hidden"
                >
                    <h1
                        class="my-4 text-3xl md:text-5xl text-white opacity-75 font-bold leading-tight text-center md:text-left"
                    >
                        {{ trans('Your personalized wall of memories') }}
                    </h1>
                    <p
                        class="leading-normal text-base md:text-2xl mb-8 text-center md:text-left"
                    >
                        {{ trans('Subscribe to our newsletter to receive updates about when we go live') }} !
                    </p>

                    <form
                        class="bg-zinc-900 opacity-75 w-full shadow-lg rounded-lg px-8 pt-6 pb-8 mb-4"
                        @submit.prevent="submit"
                    >
                        <div class="mb-4">
                            <label
                                class="block text-blue-300 py-2 font-bold mb-2"
                                for="email"
                            >
                                {{ trans('Sign up for our newsletter') }}
                            </label>
                            <input
                                v-model="form.email"
                                class="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:ring transform transition hover:scale-105 duration-300 ease-in-out"
                                id="email"
                                type="text"
                                :placeholder="trans('email@example.com')"
                            />
                        </div>

                        <div class="flex items-center justify-between pt-4">
                            <button
                                :class="{ 'opacity-25': form.processing }"
                                :disabled="form.processing"
                                class="bg-primary-700 hover:bg-primary-800 text-white font-bold py-2 px-4 rounded focus:ring transform transition hover:scale-105 duration-300 ease-in-out"
                                type="submit"
                            >
                                {{ trans('Subscribe') }}
                            </button>
                        </div>
                    </form>
                </motion.div>

                <!--Footer-->
                <div
                    class="pb-6 text-sm text-center md:text-left fade-in absolute bottom-0"
                >
                    <a
                        class="text-gray-300 no-underline hover:no-underline"
                        href="https://infinidev.ro"
                        target="_blank"
                        >infinidev.ro</a
                    >
                </div>
            </div>
        </div>
    </div>
</template>
